.getting-started-wrapper {
    position: relative;
    margin: 0;
    padding-top: 100px;
    overflow: hidden;

    .bg-gradient {
        height: 600px;
        top: -250px;

        @media (max-width: 860px) {
            top: -400px;
            height: 800px !important;
        }

        @media (min-width: 861px) {
            @supports not (overflow-x: clip) {
                top: 0;
            }
        }
    }

    @media (max-width: 860px) {
        margin-bottom: 30px;
    }

    .bg-gradient-wrapper {
        height: 221px;
        bottom: -210px;
        transform: translateY(-50%);

        @media (min-width: 861px) {
            @supports not (overflow-x: clip) {
                transform: none;
                top: auto;
                bottom: 0;
                height: 100%;
            }
        }
    }
}

.getting-started {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 0 80px;

    @media (max-width: 860px) {
        padding: 0 20px;
    }

    &__content {
        max-width: 1064px;
        margin: 0 auto;
        padding: 81px 0 83px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 860px) {
            flex-direction: column-reverse;
            padding: 70px 0 53px;

            .klickly-button {
                display: inline-flex;
            }
        }
    }

    &__head {
        margin: 0 auto 15px;
        font-family: "IBM Plex Sans Medium", Sans;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        color: #DA1781;
    }

    &__left {
        max-width: 428px;
        width: 100%;
        white-space: nowrap;
        margin-right: 108px;

        @media (max-width: 860px) {
            max-width: 100%;
            margin-top: 35px;
            margin-right: 0;
        }
    }

    &__right {
        @media (max-width: 860px) {
            width: calc(100% + 40px);
            margin-left: -20px;
            margin-right: -20px;
            overflow: hidden;
        }
    }

    &__title {
        font-family: "IBM Plex Sans Medium", Sans;
        font-weight: 700;
        font-size: 64px;
        line-height: 64px;
        color: #04080F;
        margin-bottom: 16px;
        margin-top: -20px;

        .br-mobile {
            display: none;
        }

        @media (max-width: 860px) {
            max-width: 100%;
            font-size: 48px;
            line-height: 64px;

            .br-desktop {
                display: none;
            }

            .br-mobile {
                display: block;
            }
        }
    }

    &__text {
        font-family: "IBM Plex Sans Medium", Sans;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #04080F;
        margin-bottom: 16px;

        @media (max-width: 860px) {
            max-width: 100%;
            white-space: normal;

            br {
                display: none;
            }
        }
    }

    &__image {
        display: block;
        width: 527px;
        height: 321px;
        object-fit: contain;
        margin-top: -40px;

        @media (max-width: 860px) {
            width: 527px;
            height: 322px;
            margin: 0 auto;
        }
    }
}
