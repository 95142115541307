

.klickly-header {
    position: relative;
    width: 100%;
    background: transparent;
    z-index: 3;

    @media (max-width: 860px) {
        border-bottom: 1px solid #fff;
    }

    &__content {
        max-width: 1280px;
        height: 40px;
        margin: 0 auto;
        padding: 40px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #fff;

        @media (max-width: 860px) {
            padding: 32px 20px;
            border-bottom: none;
        }
    }

    &__left {
        display: flex;
        align-items: center;
    }

    &__logo {
        display: block;
        width: 90px;
        height: 30px;
        background-image: url('../../images/klickly_logo_final.svg');
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;

        @media (max-width: 860px) {
            width: 85px;
            height: 29px;
            margin-top: 3px;
        }
    }

    &__menu-icon {
        display: none;

        @media (max-width: 667px) {
            display: inline-block;
            width: 24px;
            height: 24px;
            background-image: url('../../images/main/icons/HamburgerMenu.svg');
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 22px;
            cursor: pointer;
        }
    }

    &__nav-links {

        @media (max-width: 667px) {
            position: fixed;
            top: 67px;
            left: 0;
            width: 100%;
            height: calc(100% - 67px);
            background-color: #ffffff;
            z-index: 5;
            display: flex;
            flex-direction: column;
            padding: 0 24px;

            transform: translateX(-100%);
            transition: transform 0.2s linear;

            &_visible {
                transform: translateX(0);
                transition: transform 0.2s linear;
            }
        }
    }

    &__nav-link {
        display: inline-block;
        font-family: "IBM Plex Sans", Serif;
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
        color: #fff;
        text-decoration: none;
        margin: 0 12px;
        cursor: pointer;

        @media (max-width: 667px) {
            font-size: 24px;
            line-height: 36px;
            padding: 16px 0;
            border-bottom: 1px solid #D2D6DA;
            color: #04080F;

            &:last-child {
                border: none;
            }
        }
    }

    &__login {
        font-family: "IBM Plex Sans", Serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #fff;

        border: none;
        outline: none;
        text-decoration: none;
        background-color: transparent;
        cursor: pointer;
    }
}
