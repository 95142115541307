.landing-spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.76);
    z-index: 2;
  
    &-img {
        display: block;
    }
}