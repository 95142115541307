.space-inside {
    padding-top: 25px;
    padding-bottom: 20px;

    @media (max-width: 1280px) {
        padding-left: 45px;
        padding-right: 45px;
    }
}

.text-nowrap {
    white-space: nowrap;
}
