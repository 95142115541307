.klickly-pricing-wrapper {
    position: relative;

    .bg-gradient-wrapper {
        height: 271px;
        top: 35%;
        transform: translateY(-50%);

        .bg-gradient {
            height: 150px;

            @media (max-width: 860px) {
                height: 500px;
            }
        }

        @media (max-width: 860px) {
            top: 415px;
            height: 590px;
        }

        @media (min-width: 861px) {
            @supports not (overflow-x: clip) {
                height: 100%;
                bottom: 0;
                top: auto;
                transform: none;
            }
        }
    }
}

.klickly-pricing {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 0 80px;

    @media (max-width: 860px) {
        padding: 0 20px;
    }

    &__content {
        max-width: 1064px;
        margin: 0 auto;
        padding: 81px 0 83px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 860px) {
            flex-direction: column-reverse;
            padding: 0 0 53px;
            margin-top: -40px;

            .klickly-button {
                display: none;
            }
        }
    }

    &__head {
        margin: 0 auto 15px;
        font-family: "IBM Plex Sans Medium", Sans;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        color: #DA1781;
    }

    &__left {
        max-width: 428px;
        width: 100%;
        white-space: nowrap;
        margin-right: 108px;

        @media (max-width: 860px) {
            max-width: 100%;
            margin-right: 0;
            margin-top: 35px;
        }
    }

    &__right {
        @media (max-width: 860px) {
            width: calc(100% + 40px);
            margin-left: -20px;
            margin-right: -20px;
            overflow: hidden;
        }
    }

    &__title {
        font-family: "IBM Plex Sans Medium", Sans;
        font-weight: 700;
        font-size: 64px;
        line-height: 64px;
        color: #04080F;
        margin-bottom: 16px;

        @media (max-width: 860px) {
            max-width: 100%;
            text-align: left;
            font-size: 48px;
            line-height: 64px;
        }
    }

    &__text {
        font-family: "IBM Plex Sans Medium", Sans;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #04080F;
        margin-bottom: 16px;

        @media (max-width: 860px) {
            max-width: 100%;
            white-space: normal;

            br {
                display: none;
            }
        }
    }

    &__image {
        display: block;
        width: 607px;
        height: 371px;
        object-fit: contain;

        @media (max-width: 860px) {
            width: 527px;
            height: 322px;
            margin: 0 auto;
        }
    }

}
