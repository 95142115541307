

.klickly-footer {
    width: 100%;
    padding-bottom: 48px;
    overflow: hidden;

    @media (max-width: 860px) {
        padding: 24px 0;
    }

    &__content {
        max-width: 1280px;
        margin: 0 auto;
        padding: 24px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #04080F;

        @media (max-width: 860px) {
            flex-direction: column;
            align-items: flex-start;
            padding: 0 20px;
            border-bottom: 0;
        }
    }

    &__copyright {
        display: inline-block;
        font-family: 'IBM Plex Sans';
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #04080F;
        margin-right: 12px;

        @media (max-width: 860px) {
            color: #77838F;
            font-weight: 400;
        }
    }

    &__nav-link {
        display: inline-block;
        font-family: 'IBM Plex Sans';
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #04080F;
        text-decoration: none;
        cursor: pointer;

        @media (max-width: 860px) {
            white-space: nowrap;
            color: #77838F;
        }
    }
}

.klickly-footer {
    .footer-side {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @media (max-width: 860px) {
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        &-item {
            margin: 0 12px;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }

            &-mobile {
                display: none;
                @media (max-width: 860px) {
                    display: block;
                }
            }

            &-desktop {
                @media (max-width: 860px) {
                   display: none;
                }
            }

            @media (max-width: 860px) {
                margin: 0 24px 24px 0;

                &:has(.klickly-sensitive-info) {
                    margin-bottom: 0;
                }
            }
        }
    }
    .footer-logo {
        display: block;
        width: 90px;
        height: 30px;
        background-image: url('../../images/klickly_logo_black.svg');
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;

        @media (max-width: 860px) {
            background-image: url('../../images/klickly_logo_gray.svg');
        }
    }
}
