@import "../../../../styles/general";
@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.header {
    background: url('../../../../images/png/header.png') 100% 90% no-repeat;
    background-size: cover;
    @extend .space-inside;

    @media (max-width: 667px) {
        width: 100%;
    }

    &__content {
        max-width: 1024px;
        width: 100%;
        margin: 0 auto;
    }

    &__top {
        @include flex(row, space-between, center);
        padding-bottom: 14px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        @media (max-width: 667px) {
            flex-direction: column;
        }
    }

    &__ecommerce-checklist {
        width: 290px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2ac7fd;
        padding: 10px;
        border-radius: 4px;
        color: #ffffff;
        font-family: $font-semibold;
        font-size: 16px;
        cursor: pointer;
    }
}

.nav-links {
    @include flex(row, flex-end, center);
    @media (max-width: 667px) {
        justify-content: flex-start;
        align-self: flex-start;
        margin-top: 20px;
    }

    &__link {
        margin-left: 18px;
        margin-right: 18px;
        font-size: 20px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 24px;
        letter-spacing: normal;
        text-align: right;
        color: #ffffff;
        font-family: $font-semibold;
        text-decoration: none;
        &:last-of-type {
            margin-right: 0;
        }
        @media (max-width: 667px) {
            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}


.dropdown-menu {

    &__wrapper {
        width: 100%;

        @media (max-width: 667px) {
            margin-top: 20px;
        }
    }

    &__title {
        font-family: Montserrat, Helvetica, sans-serif;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        cursor: pointer;
    }

    &__title_arrow {
        background-image: url(../../../../images/q4campaign/down-arrow.svg);
        background-repeat: no-repeat;
        background-size: contain;
        width: 10px;
        height: 10px;
    }

    &__list-wrapper {
        list-style: none;
        background-color: #ffffff;
        border-radius: 3px;
    }

    &__list-item {
        height: 37px;
        font-family: Montserrat, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        color: #4a4a4a;
        background-color: #ffffff;
        border-radius: 3px;
        border: 1px solid #e2e2e2e2;
        padding: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:not(:first-child) {
            border-top: none;
        }
    }

    &__content {
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 1px 11px 0 rgba(0,0,0,.22);
        visibility: hidden;
        transform: translateY(-100%);
        transition: visibility 0s linear .2s, transform .2s linear;
        max-height: 0;

        &.is-opened {
            visibility: visible;

            -webkit-transform: translateY(15px);
            -moz-transform: translateY(15px);
            -ms-transform: translateY(15px);
            -o-transform: translateY(15px);
            transform: translateY(15px);

            -webkit-transition-delay: 0s;
            -moz-transition-delay: 0s;
            -ms-transition-delay: 0s;
            -o-transition-delay: 0s;
            transition-delay: 0s;
        }
    }
}
