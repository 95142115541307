

.klickly-how-works {
    width: 100%;
    height: fit-content;
    padding: 0 80px;

    @media (max-width: 860px) {
        padding: 0 20px;
    }

    &__content {
        max-width: 1064px;
        margin: 0 auto;
        padding: 77px 0 99px;
    }

    &__head {
        margin: 0 auto 16px;
        font-family: "IBM Plex Sans Medium", Sans;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #77838F;
        text-align: center;

        @media (max-width: 615px) {
           text-align: center;
            margin-top: -20px;
        }
    }

    &__title {
        font-family: "IBM Plex Sans Medium", Sans;
        font-weight: 700;
        font-size: 64px;
        line-height: 64px;
        color: #04080F;
        margin-bottom: 16px;
        text-align: center;

        br {
            display: none;
        }

        @media (max-width: 860px) {
            text-align: center;
            font-size: 48px;
            line-height: 64px;
            br {
                display: block;
            }
        }
    }

    &__subtitle {
        font-family: "IBM Plex Sans Medium", Sans;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #04080F;
        margin-bottom: 48px;
        text-align: center;
    }

    &__items {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        @media (max-width: 615px) {
            flex-direction: column;
            align-items: center;
        }
    }

    &__item {
        margin: 0 12px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        @media (max-width: 615px) {
            margin: 0 auto 70px;
            display: flex;
            flex-direction: column;
            align-items: center;

            &:first-child {
                margin: 0 auto 70px;
            }

            &:last-child {
                margin: 0 auto;
            }
        }

        &-icon {
            display: block;
            object-fit: contain;

            &_consumers {
                width: 75px;
                height: 49.29px;
                margin: 0 auto 32px;

                @media (max-width: 667px) {
                    margin: 0 0 20px;
                }
            }

            &_gauge {
                width: 75px;
                height: 50px;
                margin: 0 auto 31px;

                @media (max-width: 667px) {
                    margin: 0 0 18px;
                }
            }

            &_network {
                width: 65px;
                height: 65px;
                margin: 0 auto 24px;

                @media (max-width: 667px) {
                    margin: 0 0 18px;
                }
            }
        }

        &-title {
            font-family: "IBM Plex Sans Medium", Sans;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #04080F;
            margin-bottom: 16px;
            text-align: center;
        }

        &-text {
            font-family: "IBM Plex Sans", Sans;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #04080F;
            text-align: center;

            @media (max-width: 615px) {
               text-align: center;
            }
        }
    }
}
