@import "../../styles/font_family";
@import "../../styles/variables";

.privacy-main-wrapper {
    position: relative;

    .bg-gradient-wrapper {
        height: 250px;

        @media (max-width: 860px) {
            @supports not (overflow-x: clip) {
                height: 400px;
                top: -160px;
            }
        }

        .bg-gradient {
            transform: translateX(-50%) rotate(-7deg);
            height: 400px;
            margin-bottom: 120px;

            @media (max-width: 860px) {
                height: 600px;
                margin-bottom: -50px;
            }
        }
    }
}

.terms-privacy {
   font-family: "IBM Plex Sans", Sans;
    color: #04080F;

   &__content {
      display: flex;
      margin: 0 auto;
      max-width: 1050px;

      @media (max-width: 860px) {
         flex-direction: column;
     }


      &__left {
         width: 420px;
          margin-top: 160px;

         @media (max-width: 1200px) {
            padding-left: 70px;
        }

         @media (max-width: 860px) {
             width: 100%;
             margin-top: 0;
             padding: 0 20px;
        }
      }

      &__right {
         width: 100%;
         min-height: calc(100vh - 190px);
          margin-top: 48px;

         @media (max-width: 860px) {
            min-height: calc(100vh - 490px);
             margin-top: 0;
        }
      }
   }

   &__links {
           padding-top: 64px;

           @media (max-width: 860px) {
            padding-top: 30px;
        }
   }

   &__item {
      padding-top: 10px;
      padding-bottom: 10px;
      list-style: none;
      width: 280px;

      @media (max-width: 860px) {
         width: 100%;
      }


      &__link {
         position: relative;
         padding-top: 6px;
         padding-bottom: 6px;
         font-size: 16px;
         line-height: 24px;
         text-align: left;
         font-family: "IBM Plex Sans Medium", Sans;
         text-decoration: none;
          color: #04080F;

         &:hover {
               color: #da1781;
               transition: .4s;
         }

         &.is-active {
               color: #da1781;

               &:before {
                  visibility: visible;
                  height: 100%;
               }
         }

          @media (max-width: 860px) {
              color: #ffffff;

              &:hover {
                  color: #04080F;
              }

              &.is-active {
                  color: #04080F;
              }
          }
      }
   }
}

.privacy {
    &-wrapper {
        width: 100%;
        position: relative;
    }

    &-content {
        &__table_collect {
            padding-bottom: 25px;

            & table tr td {
                border: 1px solid #4a4a4a;
            }

            & td {
                padding: 5px;
            }
        }

        &__table_business-purpose {

            & table tr td {
                border: none;
            }

            & td {
                padding: 5px 5px 0 5px;
            }

            & td:first-child p {
                width: 90px;
            }
        }
    }

    &-inner-wrapper {
        position: relative;
        max-width: 1024px;
        margin: 0 auto;
        padding: 24px 16px 24px 16px;
        @media (max-width: 860px) {
            padding: 24px 0;
        }
    }

    &-mid-area {
        padding: 20px;
        color: #4a4a4a;
        font-family: "IBM Plex Sans", Sans;

        & > div {
            margin: 0 0 20px;
            line-height: 20px;
        }

        & p {
            margin: 0 0 20px;
            line-height: 20px;
        }

        & strong {
            font-weight: 700;
            font-family: $font-semibold;
        }

        & a {
            color: #da1781;
            text-decoration: none;
        }

        & ul {
            margin: 0 0 20px 30px;
            padding: 0;
            list-style-type: disc;
        }

        & ul > li {
            padding-top: 10px;
            line-height: 20px;
        }

        & ul > li:first-child {
            padding-top: 0;
        }
    }

    &-list-head {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;

        &-left__title {
            font-size: 64px;
            line-height: 64px;
            font-weight: 700;
            font-family: "IBM Plex Sans Medium", Sans;
            color: #04080F;

            @media (max-width: 860px) {
                font-size: 48px;
            }
        }

        &-right {
            margin-top: 15px;
            font-family: "IBM Plex Sans Medium", Sans;
            color: #04080F;
        }
    }

}
