.klickly-we-power {
    padding: 0 80px 0;
    background-color: #ffffff;
    margin-bottom: 140px;

    @media (max-width: 860px) {
        padding: 0 20px 0;
    }

    &__content {
        max-width: 1064px;
        margin: 0 auto;
    }

    &__text {
        font-family: "IBM Plex Sans Medium";
        font-size: 32px;
        line-height: 44px;
        font-weight: 500;
        color: #04080F;
        margin-bottom: 24px;

        @media (max-width: 860px) {
            text-align: center;
            font-size: 24px;
            line-height: 36px;
        }
    }

    &__items {
        display: grid;
        grid-template-columns: repeat(3, calc(100%/3));
        grid-gap: 24px;

        @media (max-width: 860px) {
            grid-template-columns: repeat(1, 100%);
        }
    }

    &__item {
        &-icon {
            display: block;
            height: 70px;
            width: auto;
            object-fit: contain;
            margin-bottom: 16px;

            @media (max-width: 860px) {
                margin: 0 auto 16px;
            }
        }

        &-title {
            font-family: "IBM Plex Sans Medium", Sans;
            font-size: 24px;
            line-height: 36px;
            font-weight: 500;
            color: #04080F;
            margin-bottom: 16px;

            @media (max-width: 860px) {
                text-align: center;
            }
        }

        &-text {
            font-family: "IBM Plex Sans", Sans;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #04080F;

            @media (max-width: 860px) {
                text-align: center;
            }
        }
    }
}
