

.klickly-testimonials {
    width: 100%;
    height: fit-content;
    background-color: #ffffff;
    padding: 0 80px;

    @media (max-width: 615px) {
        padding: 0 26px;
    }

    &__content {
        max-width: 1064px;
        width: 100%;
        margin: 0 auto;
        padding: 81px 0 83px;
    }

    &__head {
        margin: 0 auto 16px;
        font-family: "IBM Plex Sans Medium", Serif;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #77838F;
        text-align: center;
    }

    &__title {
        font-family: "IBM Plex Sans Medium", Serif;
        font-weight: 700;
        font-size: 64px;
        line-height: 64px;
        color: #04080F;
        margin-bottom: 48px;
        text-align: center;

        @media (max-width: 860px) {
            font-size: 48px;
            line-height: 64px;
            text-align: center;
            max-width: 100%;
            margin-bottom: 48px;
        }
    }

    &__items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 48px;

        width: fit-content;
        margin: 0 auto;

        @media (max-width: 965px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 845px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &__item {

        &-image {
            display: block;
            height: 72px;
            width: auto;
            margin: 0 auto 24px;

            @media (max-width: 350px) {
                width: 100%;
                height: auto;
            }
        }

        &-text {
            font-family: "IBM Plex Sans", Serif;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #04080F;

            & .bold {
                font-weight: 600;
            }
        }
    }
}
