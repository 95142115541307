.bg-gradient-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow-x: clip;

    .bg-gradient {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) rotate(-10deg);
        width: 160%;
        height: 100%;

        &.direction-left {
            transform: translateX(-50%) rotate(10deg);
        }

        @supports not (overflow-x: clip) {
            width: 100% !important;
            height: 100% !important;
            transform: none !important;
            left: 0 !important;
        }
    }

    .bg-gradient-pink {
        background: linear-gradient(-110deg, #C402A4 0%, #FF6CAC 50%);
    }
    .bg-gradient-pink-invert {
        background: linear-gradient(110deg, #C402A4 0%, #FF6CAC 50%);
    }

    .bg-gradient-orange {
        background: linear-gradient(-125.98deg, #FF8E76 17.27%, #FFA74E 59.7%);
    }

    .bg-gradient-orange-invert {
        background: linear-gradient(125.98deg, #FF8E76 17.27%, #FFA74E 59.7%);
    }
}
