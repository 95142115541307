.klickly-unified-platform-wrapper {
    position: relative;
    margin-top: -36px;

    .bg-gradient-wrapper {
        height: 196px;
        top: 30%;
        transform: translateY(-50%);

        .bg-gradient {
            height: 150px;
        }

        @media (max-width: 860px) {
           height: 274px;
            top: 10%
        }

        @supports not (overflow-x: clip) {
            top: auto;
            bottom: 0;
            transform: none;
            height: 100%;
        }
    }
}

.klickly-unified-platform {
    position: relative;
    padding: 20px 80px 0;

    @media (max-width: 860px) {
        padding: 20px 80px 50px;
    }

    @media (max-width: 860px) {
        padding: 34px 20px 64px;
    }

    &__content {
        max-width: 1064px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        @media (max-width: 860px) {
            flex-direction: column;
        }
    }

    &__left {
        margin-right: 48px;

        @media (max-width: 860px) {
           margin-right: 0;
        }
    }

    &__right {
        white-space: nowrap;

        @media (max-width: 860px) {
            white-space: normal;
        }
    }

    &__head {
        padding-top: 36px;
        margin: 0 auto 16px;
        font-family: "IBM Plex Sans Medium", Sans;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #04080F;

        @media (max-width: 860px) {
            text-align: center;
            margin-top: -40px;
        }
    }

    &__title {
        font-family: "IBM Plex Sans Medium", Sans;
        font-weight: 700;
        font-size: 64px;
        line-height: 64px;
        color: #04080F;
        margin-bottom: 16px;

        @media (max-width: 860px) {
            font-size: 32px;
            line-height: 44px;
            text-align: center;
        }
    }

    &__subtitle {
        font-family: "IBM Plex Sans Medium", Sans;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #04080F;
        margin-bottom: 16px;

        @media (max-width: 860px) {
            text-align: center;

            br {
                display: none;
            }
        }
    }

    &__image {
        width: 366px;
        height: 515px;
        object-fit: contain;

        @media (max-width: 860px) {
            width: 354px;
            height: 356px;
            margin: 0 auto;
        }
    }
}
