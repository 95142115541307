@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), local('Montserrat-Regular'), url('/fonts/Montserrat/montserrat.woff2') format('woff2'), url('/fonts/Montserrat/montserrat.woff') format('woff'), url('/fonts/Montserrat/montserrat.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url('/fonts/Montserrat/montserratbold.woff2') format('woff2'), url('/fonts/Montserrat/montserratbold.woff') format('woff'), url('/fonts/Montserrat/montserratbold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Sans';
    src: local('IBM Plex Sans'), local('IBM Plex Sans'), url('/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'IBM Plex Sans Medium';
    src: local('IBM Plex Sans Medium'), local('IBM Plex Sans Medium'), url('/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
