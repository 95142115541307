@import './colors.scss';
@import './variables.scss';

.main-page {
    &-wrapper {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        position: relative;
    }
}

.main {
    &-wrapper {
        width: 100%;
        height: 100vh;
        min-height: 600px;
        background-color: rgba(255, 255, 255, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-family: $font-regular;

        @media (max-width: 875px) {
            min-height: 700px;
        }

        @media (max-width: 480px) {
            min-height: 550px;
        }
    }

    &-content {
        width: 100%;
        height: 100%;
        background-image: url(../images/png/marketplace-screenshot.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;

        @media (max-width: 615px) {
            background-image: url(../images/png/marketplace-screenshot_mobile.png);
            background-size: contain;
            background-repeat: repeat;
            background-position: inherit;
        }
    }

    &-popup {
        &-wrapper {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.7);
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-content {
            width: 42%;
            min-width: 550px;
            height: fit-content;
            background-color: #ffffff;
            border-radius: 6px;
            position: absolute;
            padding: 30px 0;
            box-sizing: content-box;
            transform: translateY(-50px);

            @media (max-width: 1450px) {
                transform: translateY(-30px);
                padding: 20px 0;
            }

            @media (max-width: 650px) {
                min-width: auto;
                width: 420px;
                height: 500px;
            }

            @media (max-width: 480px) {
                width: 320px;
                height: 430px;
            }

            @media (max-width: 320px) {
                width: 290px;
                height: 400px;
            }

            @media (max-height: 600px) and (max-width: 600px) {
                transform: translateY(-20px);
            }

            &__logo-wrapper {
                width: 100%;
                height: 20%;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 650px) {
                    height: auto;
                    margin: 20px 0;
                }
            }

            &__logo {
                width: 30%;
                max-height: calc(100% - 20px);
                object-fit: contain;
                margin: 20px auto 0;

                @media (max-width: 1450px) {
                    margin: 10px auto 0;
                }

                @media (max-width: 650px) {
                    width: 50%;
                    margin: 0;
                }
            }

            &__text {
                width: calc(100% - 40px);
                font-size: 26px;
                font-family: $font-regular;
                margin: 10px 20px;
                text-align: center;

                @media (max-width: 1400px) {
                    font-size: 22px;
                }

                @media (max-width: 615px) {
                    font-size: 20px;
                }

                @media (max-width: 480px) {
                    font-size: 18px;
                }
            }

            &__img-wrapper {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &__img {
                width: 70%;
                object-fit: contain;
                margin: 0 auto;

                @media (max-width: 615px) {
                    margin: 20px auto;
                }

                @media (max-width: 320px) {
                    margin: 10px auto;
                }
            }

            &__buttons-wrapper {
                display: flex;
                justify-content: space-between;
                margin: 20px 70px 0;

                @media (max-width: 1350px) {
                    margin: 20px 50px 0;
                }

                @media (max-width: 650px) {
                    flex-direction: column;
                    align-items: center;
                    margin: 20px 0 0 0;
                }
            }

            &__button {
                width: 48%;
                height: 55px;
                background-color: $cerise;
                color: $white;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: box-shadow 0.2s ease-in-out;

                @media (max-height: 1530px) {
                    height: 50px;
                    margin: 10px 0;
                }

                @media (max-width: 1430px) {
                    height: 45px;
                    margin: 10px 0;
                }

                @media (max-width: 650px) {
                    width: 80%;
                }

                @media (max-width: 320px) {
                    height: 45px;
                }

                &:hover {
                    -webkit-box-shadow: 0px 0px 30px -9px $cerise;
                    -moz-box-shadow: 0px 0px 30px -9px $cerise;
                    box-shadow: 0px 0px 30px -9px $cerise;
                }

                & a {
                    width: 100%;
                    height: 100%;
                    border-radius: 14px;
                    color: $white;
                    font-family: $font-semibold;
                    font-size: 20px;
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: font-size 0.2s ease-in-out;

                    @media (max-width: 1450px) {
                        font-size: 19px;
                    }

                    @media (max-width: 650px) {
                        font-size: 18px;
                    }

                    &:hover {
                        font-size: 22.2px;

                        @media (max-width: 1450px) {
                            font-size: 19.2px;
                        }

                        @media (max-width: 650px) {
                            font-size: 18.2px;
                        }
                    }
                }
            }
        }
    }

    &-footer {
        &-wrapper {
            width: 100%;
            height: 60px;
            position: absolute;
            bottom: 30px;
            left: 0;
            z-index: 3;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 615px) {
                bottom: 10px;
            }
        }

        &-content {
            width: 700px;
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;

            @media (max-width: 615px) {
                width: 100%;
            }

        }

        &__link-wrapper {
            width: fit-content;
            height: fit-content;
            padding: 8px 0;
        }

        &__link {
            width: 100%;
            color: $white;
            opacity: 0.7;
            margin: 8px;
            transition: opacity 0.2s ease-in-out;
            text-decoration: none;
            cursor: pointer;
            font-size: 16px;
            font-family: $font-regular;

            @media (max-width: 615px) {
                font-size: 12px;
            }

            @media (max-width: 360px) {
                font-size: 10px;
            }

            &:hover {
                opacity: 1;
            }
        }
    }
}
