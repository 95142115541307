.klickly-supercharged-2-wrapper {
    position: relative;
    padding-top: 100px;

    .bg-gradient-wrapper {
        .bg-gradient {
            height: 200px;
            top: 30%;
        }

        @supports not (overflow-x: clip) {
            height: 300px;
        }
    }
}

.klickly-supercharged-2 {
    padding: 46px 0;
    position: relative;

    @media (max-width: 860px) {
        margin-top: -40px;
        padding: 64px 20px 64px;
    }

    &__content {
        max-width: 1064px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 860px) {
            flex-direction: column-reverse;
        }
    }

    &__left {
        margin-right: 48px;

        text-align: right;
        white-space: nowrap;

        .klickly-button {
            display: inline-flex;

            @media (max-width: 860px) {
                display: block;
            }
        }

        @media (max-width: 860px) {
           margin-right: 0;
            white-space: normal;
        }
    }

    &__head {
        margin: 0 auto 16px;
        font-family: "IBM Plex Sans Medium", Sans;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #04080F;
        text-align: left;

        @media (max-width: 860px) {
            text-align: center;
            margin-top: -50px;
        }
    }

    &__title {
        font-family: "IBM Plex Sans Medium", Sans;
        font-weight: 700;
        font-size: 64px;
        line-height: 64px;
        color: #04080F;
        margin-bottom: 16px;
        text-align: left;

        @media (max-width: 860px) {
            font-size: 32px;
            line-height: 44px;
            text-align: center;
        }
    }

    &__subtitle {
        font-family: "IBM Plex Sans Medium", Sans;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #04080F;
        margin-bottom: 16px;
        text-align: left;

        @media (max-width: 860px) {
            text-align: center;
        }
    }

    &__right {
        @media (max-width: 860px) {
        }
    }

    &__image {
        width: 366px;
        height: 529px;
        object-fit: contain;
        filter: drop-shadow(0px 8px 40px rgba(0, 0, 0, 0.10));
        border-radius: 8px;

        @media (max-width: 860px) {
            width: 335px;
            height: 429px;
        }
    }
}
