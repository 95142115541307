
.spin-container {
    border-top: 70px solid #1c1c1c;
    border-bottom: 145px solid #e51937;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &_mobile {
        width: 100%;
        height: 100vh;
        border-top: 71px solid #1c1c1c;
        border-bottom: 170px solid #e51937;
        display: none
    }
}

.spin-main_mobile {
    width: 300px;
    height: auto;
}

@media only screen and (max-width: 950px) {
    #mobilecenter {
        padding: 0;
        width: 320px;
        margin: 0 auto
    }

    #desktoponly, .desktoponly {
        display: none
    }

    #mobileonly, .mobileonly {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}