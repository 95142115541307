

.klickly-metrics {
    width: 100%;
    height: fit-content;
    padding: 40px 80px 0;

    @media (max-width: 860px) {
        padding: 0 20px;
    }

    &__content {
        max-width: 1064px;
        margin: 0 auto;
        padding: 81px 0 83px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 860px) {
            padding: 48px 0 0;
        }
    }

    &__head {
        margin: 0 auto 16px;
        font-family: "IBM Plex Sans Medium", Sans;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #A4ADB4;
        text-align: center;
    }

    &__left {
        max-width: 291px;
        width: 100%;

        @media (max-width: 860px) {
            max-width: 100%;
            margin-bottom: 56px;
        }
    }

    &__right {
        @media (max-width: 860px) {
            width: 100%;
        }
    }

    &__title {
        font-family: "IBM Plex Sans Medium", sans-serif;
        font-weight: 700;
        font-size: 64px;
        line-height: 64px;
        color: #04080F;
        margin-bottom: 16px;
        text-align: center;

        br {
            display: none;
        }

        @media (max-width: 860px) {
            max-width: 100%;
            font-size: 48px;
            line-height: 64px;
            text-align: center;

            br {
                display: block;
            }
        }
    }

    &__text {
        font-family: "IBM Plex Sans Medium", Sans;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #04080F;
        text-align: center;

        @media (max-width: 860px) {
            max-width: 100%;
        }
    }

    &__items {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        @media (max-width: 860px) {
            flex-direction: column;
        }
    }

    &__item {
        width: 25%;
        margin: 0 12px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-left: 0;
        }

        .pink {
            color: #F542D0;
        }

        .pink-light {
            color: #FF6DAC;
        }

        .orange {
            color: #FF956B;
        }

        .yellow {
            color: #FFC61B;
        }

        @media (max-width: 860px) {
            width: 100%;
            margin: 0 0 48px;
            text-align: center;
        }

        &-amount {
            width: fit-content;
            font-family: "IBM Plex Sans", Sans;
            font-size: 48px;
            line-height: 64px;
            font-weight: 500;
            padding-left: 10px;
            margin-bottom: 8px;

            @media (max-width: 860px) {
                margin: 0 auto;
            }
        }

        &-text {
            font-family: "IBM Plex Sans Medium", Sans;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: #04080F;
            margin-bottom: 24px;
            min-height: 48px;
        }

        &-description {
            font-family: "IBM Plex Sans", Sans;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #04080F;
        }
    }
}
