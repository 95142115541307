@import "../../../../styles/variables";

.disclosure-sens-info {
    &-wrapper {
        width: 100%;
        min-height: calc(100vh - 160px);
        max-width: 1024px;
        margin: 0 auto;
        padding: 44px 36px 44px 36px;
        color: #4a4a4a;
        font-family: "IBM Plex Sans", Sans, sans-serif;

        & a {
            color: #da1781;
            text-decoration: none;
        }

        @media (max-width: 860px) {
            padding: 44px 20px;
        }
    }
    &-head {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;

        &__right {
            @media (max-width: 667px) {
                margin-bottom: 30px;
            }
        }

        &__title {
            position: relative;
            margin-bottom: 10px;
            font-size: 64px;
            line-height: 64px;
            font-weight: 700;
            font-family: "IBM Plex Sans Medium", Sans;
            color: #04080F;

            @media (max-width: 1200px) {
                width: 350px;
            }

            @media (max-width: 860px) {
                font-size: 48px;
            }
        }

        &__last-updated {
            margin: 15px 0 10px;
            line-height: 18px;
            font-family: "IBM Plex Sans Medium", Sans;
            color: #04080F;
        }
    }
    &-content {
        line-height: 18px;
        color: #4a4a4a;

        &__list-info {
            padding: 10px 0 10px 50px;

            &__items li {
                padding-top: 5px;
            }
        }

        &__desc_mail {
            text-decoration: none;
            color: #2ea2f8;
        }
    }

    &-foot {
        padding: 10px 0 20px 0;
        line-height: 18px;

        &__link {
            text-decoration: none;
            color: #da1781;
        }
    }
}
