@import '../../styles/variables';

.sb {
    font-family: $font-semibold !important;
    font-weight: 600;
}

.campaign-page {
    &-section1 {
        &__title {
            font-family: $font-bold;
            font-weight: 600;
            font-size: 46px;
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            padding-top: 50px;
            line-height: 1.2em;

            @media (max-width: 667px) {
                font-size: 28px;
            }
        }

        &__subtitle {
            font-family: $font-regular;
            font-style: italic;
            font-size: 38px;
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            line-height: 1.2em;
            padding: 20px 0 50px;

            @media (max-height: 630px) {
                font-size: 28px;
                padding: 20px 0 30px;
            }

            @media (max-width: 667px) {
                font-size: 24px;
            }
        }

        &__logos {
            &-wrapper {
                width: 100%;
                height: fit-content;
                margin-bottom: 40px;

                @media (max-height: 630px) {
                    margin-bottom: 20px;
                }
            }

            &-content {
                max-width: 1100px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-wrap: wrap;
                margin: 0 auto;

                @media (max-height: 630px) {
                    max-width: 850px;
                }
            }
        }

        &__logo {
            width: 210px;
            height: 40px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin: 30px 0;

            @media (max-height: 800px) {
                margin: 20px 0;
            }

            @media (max-height: 630px) {
                width: 180px;
                height: 40px;
            }

            @media (max-width: 667px) {
               width: 140px;
               height: 30px;
            }

            &_clickpost {
                background-image: url(../../images/q4campaign/logos/clickpost.png);
            }

            &_fera {
                background-image: url(../../images/q4campaign/logos/fera.png);
            }

            &_growave {
                background-image: url(../../images/q4campaign/logos/growave.png);
            }

            &_klickly {
                background-image: url(../../images/q4campaign/logos/klickly.png);
            }

            &_recart {
                background-image: url(../../images/q4campaign/logos/recart.png);
            }

            &_refferalcandy {
                background-image: url(../../images/q4campaign/logos/referralcandy.png);
            }

            &_searchanise {
                background-image: url(../../images/q4campaign/logos/searchanise.png);
            }

            &_searchanise {
                background-image: url(../../images/q4campaign/logos/searchanise.png);
            }

            &_smsbump {
                background-image: url(../../images/q4campaign/logos/smsbump.png);
            }

            &_sufio {
                background-image: url(../../images/q4campaign/logos/sufio.png);
            }
        }
    }


    &-section2 {
        &__text-field {
            &-wrapper {
                width: 100%;
                height: fit-content;
                padding: 50px 0;
                background-color: #e4e4e4e4;
            }

            &-content {
                max-width: 650px;
                margin: 0 auto;
                text-align: center;
            }
        }

        &__text {
            font-family: $font-regular;
            font-size: 18px;
            line-height: 1.4em;
            color: #4a4a4a;
            margin: 20px 0;

            @media (max-width: 667px) {
                margin: 20px 10px;
             }
        }
    }

    &-section3 {

        &__partners-wrapper {
            margin-bottom: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width: 667px) {
                margin-bottom: 30px;
            }
        }

        &__partners-title {
            font-family: $font-semibold;
            font-size: 46px;
            line-height: 1.2em;
            margin: 70px 30px 30px 30px;
            text-align: center;

            @media (max-height: 630px) {
                font-size: 36px;
            }

            @media (max-width: 667px) {
                margin: 40px 20px 20px;
                font-size: 28px;
            }
        }
    }

    &__back-to-top-button {
        width: 50px;
        height: 50px;
        background-color: #da1781;
        border-radius: 50%;
        position: sticky;
        bottom: 50px;
        right: 30px;
        margin: 0 0 20px auto;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 27px;

        &-image {
            background-image: url(../../images/q4campaign/up_arrow.svg);
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &__form {

        &-wrapper {
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            bottom: 0;
            z-index: 6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color:rgba(0, 0, 0, 0.7);

            &_hidden {
                display: none;
            }
        }

        &-close {
            font-size: 20px;
            color: #ffffff;
            width: fit-content;
            margin: 0 0 5px auto;
            cursor: pointer;
        }
    }

    & .header__top {
        @media (max-width: 667px) {
            flex-direction: column-reverse;
        }
    }
}
