.klickly-input-wrapper {
    $error-font-size: 10px;
    position: relative;
    width: 100%;
    font-family: Montserrat, Helvetica, sans-serif;
    font-weight: 300;

    label {
        display: block;
        font-size: 16px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        font-family: Montserrat, Helvetica, sans-serif;
        font-weight: 300;
        letter-spacing: normal;
        text-align: left;
        color: #77838F;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        & .klickly-input__label-text {
            position: absolute;
            top: 25%;
            left: 24px;
            height: 50%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transform: translateY(0%);
            transition: all 0.3s ease;

            font-size: 16px;

            &_on-top {
                transform: translateY(-50%);
                font-size: 12px;
                transition: all 0.3s ease;
            }
        }
    }

    input {
        width: 100%;
        padding: 28px 24px 12px;
        background-color: #F4F4F4;
        border: none;
        // color: $giftly-black;
        font-size: 16px;
        line-height: 24px;
        outline: none;
        border: none;

        &:focus + label .klickly-input__label-text {
            transform: translateY(-50%);
            font-size: 12px;
            transition: all 0.3s ease;
        }

        &:-webkit-autofill {
            -webkit-box-shadow: none;
            box-shadow: none;
            background-color: #F4F4F4 !important;
        }
    }

    &.input-with-error {
        padding-bottom: 0;
        input {
            background-color: #fbe8ea;
            // color: $scarlet;
        }
        label, .error-message {
            color: #f64316;
        }
        .error-message {
            min-height: $error-font-size;
            font-size: $error-font-size;
            text-align: right;
            line-height: 1;

            position: absolute;
            right: 0;
            bottom: 2px;
        }
    }
}