@import "../../styles/mixins";

.press-page {
    @include flex(column, space-between, stretch);
    width: 100%;
    height: 100vh;

    .klickly-header {
        background: linear-gradient(-110deg, #C402A4 0%, #FF6CAC 50%);
    }

    &__iframe {
        width: 100%;
        min-height: calc(100vh - 170px);
        height: 100%;
    }

    &__content {
        width: 100%;
        height: 100%;
        background: #ffffff;
    }

    .footer {
        position: relative;
    }
}
