@import "./font_family";
@import "./variables";
@import "./colors";
@import "./mixins";

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html, body {
  height: 100%; }

* {
  box-sizing: border-box; }

body {
  font-family: $font-regular;

  &.open-modal {
    overflow: hidden;
  }
}

.full {
  width: 100%; }

.half {
  width: 48% !important;
}

.form-group {
  position: relative;
  margin-bottom: 24px;

    @media screen and (min-width: 320px) and (max-width: 959px) {
      margin-bottom: 15px;
    }
}

.error-message {
  display: block;
  position: absolute;
  right: 5px;
  bottom: -20px;
  font-size: 13px;
  font-weight: 600;
  color: $scarlet; }

.text-center {
  text-align: center; }

.to-left {
  margin-right: auto; }

.to-right {
  margin-left: auto; }

._hide {
  display: none !important; }

._show {
  display: block !important; }

.main-wrapper {
  position: relative;
}

.mid-area {
  padding: 20px;
  color: $charcoal-grey;
  font-family: $font-light;
  p {
    margin: 0 0 20px;
    line-height: 18px;
  }
  .b,
  optgroup,
  strong {
    font-weight: 700;
    font-family: $font-semibold;
  }
  ol, ul {
    margin-bottom: 10px;
    margin-left: 30px;
    padding: 0;
    list-style-type: disc;

    & > li {
      padding-top: 5px;
    }

    & > li:first-child {
      padding-top: 0;
    }
  }

  a {
    color: $cerise;
    text-decoration: none;
  }
}
.list_hed {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .right {
    margin-top: 15px;
  }
}

.privacy-content__desc_mail {
  text-decoration: none;
  color: #2ea2f8 !important;
}

.privacy-content__point {
  padding-bottom: 10px;
  margin: 0 !important;
}

.privacy,
.terms {
  .footer {
    position: relative;
  }
  .title {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: 700;
    font-family: $font-bold;
  }
  .k-inner-wrapper {
    padding-bottom: 24px;
  }
}
.k-header {
    @include flex(row, space-between, center);
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    @media (max-width: 667px) {
        @include flex(column, flex-start, flex-start);
    }
}

.k-hero {
  padding: 60px 60px 0 60px;
  color: #fff; }
.k-hero__title {
  font-size: 37px;
  font-weight: bold;
  line-height: 1.19;
  letter-spacing: 0;
  text-align: left; }
.k-hero__desc {
  font-size: 17px;
  line-height: 1.65;
  margin: 16px 0 22px 0; }
.k-why {
  padding-top: 50px;
}
.k-why-list {
  display: flex;
  text-align: center;
  margin-top: 70px;
  position: relative;}

.k-why-list:before {
  display: block;
  position: absolute;
  left: 50%;
  top: -28px;
  content: '';
  height: 126px;
  width: 1px;
  background-color: $pinkish-grey-dk;
  transform: translateX(-50%);
}

.k-why-list__item {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }

.k-why-list__title {
  margin-top: 40px;
  font-size: 20px;
  letter-spacing: -0.2px;
  color: $cerise;
}

.k-why-list__desc {
    max-width: 80%;
    margin: 22px auto;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: $charcoal-grey;
}

.k-scores {
    @include flex(row, center, flex-start);
    margin-top: 35px;
    padding: 35px 0;
    background-color: $white-3;
    @media (max-width: 959px) {
        flex-direction: column;
        margin-top: 0;
    }
    @media (max-width: 667px) {
        margin-right: -12px;
        margin-left: -12px;
    }
}

.k-how {
    margin-top: 55px;

    &-list {
        @include flex(row, flex-start, flex-start);
        margin-top: 43px;
        flex-wrap: nowrap;
        @media (max-width: 959px) {
            flex-direction: column;
        }

        &__item {
            width: 33.33333%;
            padding: 40px 5px;
            @include flex(row, flex-start, flex-start);
            @media (max-width: 959px) {
                @include flex(column, center, center);
                align-items: center;
                width: 100%;
            }
            @media (max-width: 667px) {
                padding: 0 5%;
            }
        }

        &__icon {
            margin-right: 30px;
            @media (max-width: 959px) {
                align-self: center;
                margin-right: 0;
                margin-bottom: 26px;
            }
        }

        &__title {
            font-size: 21px;
            font-weight: 600;
            letter-spacing: -0.3px;
            color: $cerise;
        }

        &__desc {
            font-size: 15px;
            line-height: 1.8;
            color: $charcoal-grey;
            margin-top: 15px;
            @media (max-width: 959px) {
                width: 98%;
                margin: 15px auto 30px;
            }
        }

        &-attrs {
            @media (max-width: 959px) {
                text-align: center;
            }
        }
    }
}

.k-footer {
    @include flex(column, center, center);
    min-height: 374px;
    margin-top: 40px;
    @media (max-width: 959px) {
        min-height: 596px;
    }

    &__title {
        margin-bottom: 35px;
        color: #fff;
        font-size: 23px;
        font-weight: 600;
        text-align: center;
    }
}

.k-footer-bottom {
  position: relative;
  margin: 0 auto;
  padding: 0 40px;
}

.k-footer-bottom__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 20px;
  border-top: 3px solid #ffffff;
}

.k-footer-bottom__links {
  display: flex;
  align-items: flex-start;
}

.k-footer-bottom__link {
  position: relative;
  margin-left: 20px;
  padding: 0 3px;
  text-decoration: none;
  cursor: pointer;
}

.k-footer-bottom__link:before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: #ffffff;
}

.k-footer-bottom__link:hover:before,
.k-footer-bottom__link:active:before {
  width: 100%;
  transition: .4s;
}

.k-footer-bottom__link:hover,
.k-footer-bottom__link:active {
  color: #ffffff;
}

.k-footer-bottom__text {
  font-family: $font-semibold;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  line-height: 14px;
}

.k-how-start-steps {
    @include flex(row, flex-start, flex-start);
    color: $charcoal-grey;
    margin-bottom: 53px;

    &__step {
        @include flex(row, flex-start, center);
        align-self: stretch;
        width: 33%;
        margin-right: 15px;
        padding: 20px 30px;
        border-radius: 5px;
        background-color: #ffffff;
        box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.15);

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__icon {
        margin-right: 20px;
    }

    &__title {
        font-size: 17px;
        font-weight: 600;
        letter-spacing: -0.4px;
    }

    &__desc {
        font-size: 12px;
        line-height: 16px;
    }
}

.k-score {
  text-align: center;
  margin-right: 100px; }
.k-score:last-of-type {
  margin-right: 0; }
.k-score__title {
  margin-top: 25px;
  font-size: 18px;
  color: $purpley-grey; }

.k-heading {
  max-width: 650px;
  margin: 0 auto;
  text-align: center; }
.k-heading__title {
  font-size: 26px;
  font-weight: 600;
  color: $biscay;
  font-family: $font-bold;
}
.k-heading__desc {
  margin-top: 10px;
  font-size: 21px;
  font-weight: 300;
  line-height: 1.43;
  text-align: center;
  color: $biscay; }

.k-bold {
  font-weight: bold; }

.k-logo {
    width: 110px;
    height: 39px;
    background-image: url(../images/svg/klickly-logo-final.svg);
    @media (max-width: 667px) {
        align-self: flex-start;
    }
}

.k-btn {
  display: inline-block;
  outline: 0;
  color: #fff;
  cursor: pointer; }

.k-btn-login {
  font-size: 18px;
  font-weight: 600;
  padding: 10px 36px;
  background-color: transparent; }

.k-btn-request {
  font-weight: 600;
  padding: 13px 65px;
  background-color: $bright-sky-blue;
  border-radius: 3px;
  font-size: 20px; }

.k-btn-submit {
  font-weight: 600;
  padding: 13px 65px;
  color: $bright-sky-blue;
  border: solid 1px $bright-sky-blue;
  border-radius: 3px;
  font-size: 20px;
  background: transparent;}

.modal-overlay {
  width: 100%;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  background: rgba(34, 34, 34, 0.4);
  z-index: -1;
  overflow: hidden;
  display: block;
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: opacity .15s linear;
}
.modal-overlay--active {
  z-index: 3;
  overflow-y: auto;
  visibility: visible;
  opacity: 1;
  height: 100%;
  transition: opacity .15s linear;
}

input::-webkit-input-placeholder {
  font-weight: 300;
  font-family: $font-regular;
  font-size: 21px;
  color: $purpley-grey;
}
input:-moz-placeholder {
  font-weight: 300;
  font-family: $font-regular;
  font-size: 21px;
  color: $purpley-grey;
}
input:-ms-placeholder {
  font-weight: 300;
  font-family: $font-regular;
  font-size: 21px;
  color: $purpley-grey;
}
textarea::-webkit-input-placeholder {
  font-weight: 300;
  font-family: $font-regular;
  font-size: 21px;
  color: $purpley-grey;
}
textarea:-moz-placeholder {
  font-weight: 300;
  font-family: $font-regular;
  font-size: 21px;
  color: $purpley-grey;
}
textarea:-ms-placeholder {
  font-weight: 300;
  font-family: $font-regular;
  font-size: 21px;
  color: $purpley-grey;
}

@media screen and (min-width: 320px) and (max-width: 959px) {

  .spinner {
    &-img {
      display: block;
      width: 100px;
      height: 100px;
    }
  }

  input::-webkit-input-placeholder {
    font-size: 13px;
  }
  input:-moz-placeholder {
    font-size: 13px;
  }
  input:-ms-placeholder {
    font-size: 13px;
  }
  textarea::-webkit-input-placeholder {
    font-size: 13px;
  }
  textarea:-moz-placeholder {
    font-size: 13px;
  }
  textarea:-ms-placeholder {
    font-size: 13px;
  }

  .half {
    width: 100% !important; }
  .banner__title:before {
    right: 8px;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .k-footer-bottom__wrapper {
    flex-direction: column-reverse;
    align-items: center;
  }
  .k-footer-bottom__links {
    flex-direction: column;
    align-items: center;
  }
  .k-footer-bottom__text {
    white-space: nowrap;
  }
  .k-footer-bottom__link {
    margin-left: 0;
    margin-bottom: 8px;
  }
  .terms {
    .list_hed {
      flex-direction: column;
    }
  }
}
