.klickly-our-customers-wrapper {
    position: relative;

    .bg-gradient-wrapper {

        .bg-gradient {
            transform: translateX(-50%) rotate(-7deg);
            height: 400px;
            margin-bottom: 120px;

            @media (max-width: 860px) {
                margin-bottom: 150px;
            }
        }
    }
}

.klickly-our-customers {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 0 80px;

    @media (max-width: 860px) {
        padding: 0 20px;
    }

    &__content {
        max-width: 1064px;
        margin: 0 auto;
        padding: 77px 0 0;

        @media (max-width: 615px) {
            padding: 54px 0 0;
        }
    }

    &__title {
        font-family: "IBM Plex Sans Medium", Serif;
        font-weight: 700;
        font-size: 64px;
        line-height: 64px;
        color: #04080F;
        margin-bottom: 24px;
    }

    &__text {
        font-family: "IBM Plex Sans Medium", Serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #04080F;

        @media (max-width: 860px) {
           br {
               display: none;
           }
        }
    }
}
