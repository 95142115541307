.klickly-brands-cards-wrapper {
    position: relative;

    .bg-gradient-wrapper {
        .bg-gradient {
            height: 300px;
            margin-bottom: 50px;

            @media (max-width: 860px) {
                height: 1000px;
            }
        }

        @supports not (overflow-x: clip) {
            margin-top: 50px;
        }
    }
}

.klickly-brands-cards {
    position: relative;
    max-width: 1064px;
    margin: 0 auto;
    height: fit-content;
    padding: 128px 0 60px;

    @media (max-width: 860px) {
        padding: 40px 26px 0;
    }

    &-content {
        display: grid;
        grid-template-columns: repeat(3, calc(100%/3));
        grid-gap: 19.5px;

        width: fit-content;

        @media (max-width: 860px) {
            grid-template-columns: repeat(1, 100%);
            text-align: center;
            margin: 0 auto;
        }
    }

    &__card {
        background: #FFFFFF;
        padding: 24px 20px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;


        &-image {
            object-fit: contain;
            display: block;
            width: auto;
            height: 72px;
            margin: 0 auto 24px;

            &_anese {
            }

            &_310 {
            }

            &_vegamour {
            }

            &_utz {
            }

            &_four-sigmatic {
            }

            &_kiss-my-keto {
            }
        }

        &-text {
            font-family: Montserrat, Helvetica, sans-serif;
            font-size: 15px;
            line-height: 26px;
            font-weight: 300;
            color: #1C3144;

            & .bold {
                font-weight: 600;
            }
        }
    }

    &__rewards {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 130px;

        @media (max-width: 860px) {
            flex-direction: column;
            padding-top: 90px;

        }
    }

    &__reward {
        position: relative;
        display: block;
        object-fit: contain;
        z-index: 2;
        width: 322px;
        height: 340px;

        margin-right: 48px;

        &:last-child {
            margin-right: 0;
        }

        @media (max-width: 860px) {
            margin: 0 auto 24px;

            &:last-child {
                margin-right: auto;
            }
        }
    }
}

.pink {
    color: #DA1781;
}
