@mixin flex($direction: row, $justify-content: flex-start, $align: flex-start){
    display: flex;
    flex-direction: $direction;
    justify-content: $justify-content;

    @if $direction == row {
        align-items: $align
    }

    @if $direction == column {
        align-content: $align
    }
}
