.klickly-contact-sales-wrapper {
    position: relative;

    .bg-gradient-wrapper {
        height: calc(100% - 34px);
        margin-top: -82px;

        .bg-gradient {
            height: 500px;
            top: -50%;

            @media (max-width: 860px) {
                top: -100px;
                height: 300px;
            }
        }

        @media (max-width: 860px) {
            height: calc(100% + 42px);
            margin-top: -65px;
        }
    }
}

.klickly-contact-sales {
    width: 100%;
    height: fit-content;
    padding: 0 80px;
    position: relative;

    @media (max-width: 860px) {
        padding: 0 20px;
    }

    &__content {
        max-width: 1064px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 860px) {
            flex-direction: column-reverse;
            margin-bottom: 54px;
        }
    }

    &__left {
        white-space: nowrap;
        z-index: 2;
    }

    &__title {
        font-family: "IBM Plex Sans Medium", Sans;
        font-weight: 700;
        font-size: 64px;
        line-height: 64px;
        color: #04080F;
        margin-bottom: 24px;

        @media (max-width: 860px) {
            font-size: 48px;
            line-height: 48px;
            text-align: center;
        }
    }

    &__subtitle {
        font-family: "IBM Plex Sans Medium", Sans;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #04080F;
        margin: 0 0 24px;

        @media (max-width: 860px) {
            text-align: center;
        }
    }

    &__right {

        @media (max-width: 860px) {
            margin-bottom: -100px;
        }
    }

    &__image {
        width: 691px;
        height: 544px;
        object-fit: contain;

        @media (max-width: 860px) {
            width: 375px;
            height: 508px;
            margin-top: -65px;
        }
    }
}
