.klickly-data-cards-title {
    font-family: "IBM Plex Sans Medium", Sans;
    font-size: 32px;
    line-height: 44px;
    font-weight: 500;
    margin-bottom: 50px;

    @media (max-width: 860px) {
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 27px;
    }
}

.klickly-data-cards {
    max-width: 1064px;
    height: fit-content;
    margin: 0 auto 80px;

    &-content {
        margin: 0 auto;

        display: grid;
        grid-template-columns: repeat(3, calc(100%/3));
        grid-gap: 24px;

        width: fit-content;

        @media (max-width: 860px) {
            grid-template-columns: repeat(1, 100%);
            margin-bottom: -80px;
        }
    }

    &__card {
        margin-bottom: 24px;
        background: #FFFFFF;

        @media (max-width: 860px) {
            margin-bottom: 0;
        }

        &-left {
            @media (max-width: 860px) {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &-icon {
            width: 72px;
            height: 72px;
            object-fit: contain;
            margin-bottom: 16px;
        }

        &-title {
            font-family: "IBM Plex Sans Medium", Sans;
            font-size: 24px;
            line-height: 36px;
            font-weight: 500;
            color: #04080F;
            margin-bottom: 16px;

            @media (max-width: 860px) {
                text-align: center;
            }
        }

        &-text {
            font-family: "IBM Plex Sans", Sans;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #04080F;

            @media (max-width: 860px) {
                text-align: center;
            }
        }
    }
}
