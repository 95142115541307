.klickly-textarea-wrapper {
    $error-font-size: 10px;
    position: relative;
    width: 100%;
    font-family: Montserrat, Helvetica, sans-serif;
    font-weight: 300;

    label {
        display: block;
        font-size: 16px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        font-family: Montserrat, Helvetica, sans-serif;
        font-weight: 300;
        letter-spacing: normal;
        text-align: left;
        color: #77838F;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        & .klickly-textarea__label-text {
            padding-left: 24px;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transform: translateY(0);
            transition: all 0.3s ease;

            font-size: 16px;

            &_on-top {
                transform: translateY(-1px);
                font-size: 12px;
                height: 44px;
                padding: 10px 0 10px 24px;
                transition: all 0.3s ease;
                width: calc(100% - 20px - 24px);
                background-color: #F4F4F4;
            }
        }
    }

    textarea {
        width: 100%;
        padding: 40px 24px 12px;
        background-color: #F4F4F4;
        border: none;
        // color: $giftly-black;
        font-size: 16px;
        line-height: 24px;
        outline: none;
        border: none;
        resize: vertical;

        &:focus + label .klickly-textarea__label-text {
            transform: translateY(-1px);
            font-size: 12px;
            height: 44px;
            padding: 10px 0 10px 24px;
            transition: all 0.3s ease;
            width: calc(100% - 20px - 24px);
            background-color: #F4F4F4;
        }

        &:-webkit-autofill {
            -webkit-box-shadow: none;
            box-shadow: none;
            background-color: #F4F4F4 !important;
        }
    }

    &.textarea-with-error {
        padding-bottom: 0;
        textarea {
            background-color: #fbe8ea;
            // color: $scarlet;
        }
        label, .error-message {
            color: #f64316;
        }
        .error-message {
            min-height: $error-font-size;
            font-size: $error-font-size;
            text-align: right;
            line-height: 1;

            position: absolute;
            right: 0;
            bottom: 2px;
        }
    }
}