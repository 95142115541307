$white: #ffffff;
$white-one: #e7e7e7;
$white-two: #fbfbfb;
$white-3: #f3f4f5;
$black: #000000;
$cerise: #da1781;
$lipstick: #ba166f;
$scarlet: #d0011b;
$dark-cream: #fff1a3;
$pale-cream: #fffcce;
$sunflower-yellow: #ffd700;
$violet-pink: #ff41f2;
$heliotrope: #df33ff;
$violet: #bd0fe1;
$silver-one: #dfe2e5;
$silver-two: #ced0da;
$silver: #dfe0e4;
$dropdown-hover: rgb(231, 231, 231);
$gray-in-active: #e2e2e2;
$gray: #e3e3e3;
$gray-lt: #fafafa;
$gray-lt-two: #f8f8f8;
$greyish: #afafaf;
$pale-grey: #dfe3e9;
$pale-grey-two: #f1f4f8;
$pale-grey-tree: #f2f4f7;
$whisper: #e5e5e5;
$pinkish-grey: #c7c7c7;
$pinkish-grey-dk: #c6c6c6;
$cool-grey: #a8aab7;
$cool-grey-two: #abaeae;
$purpley-grey: #9b9b9b;
$warm-grey: #979797;
$white-smoke: #f2f2f2;
$charcoal-grey: #4a4a4a;
$mirage: #3c4144;
$charcoal-grey-two: #333c48;
$dark-grey-blue: #354052;
$biscay: #313d4f;
$cloud-burst: #353c48;
$light-grey-blue: rgba(53, 64, 82, 0.5);
$text-stroke: rgba(151, 151, 151, 0.15);
$solitude: #e2e7ee;
$cloudy-blue: #c7d2e0;
$blue-grey: #7f8fa4;
$light-blue-grey: #d5dce6;
$alice-blue: #f2f6f8;
$alice-blue-two: #ebf1f6;
$pattens-blue: #d8e4ee;
$duck-egg-blue: #f6fafe;
$pattens-blue-two: #c8d8e4;
$bluey-grey: #a2b1c1;
$light-steel-blue: #a1bed6;
$rock-blue: #97aac4;
$metallic-blue: #506882;
$pale-sky-blue: #beefff;
$bright-sky-blue: #2ac7fd;
$blue: #0baff0;
$dodger-blue: #2ea2f8;
$green: #87b576;
$squash: #f6a623;
$error-bg: rgba(248, 232, 28, 0.28);

