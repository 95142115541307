
.klickly-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    height: 48px;
    background-color: #04080F;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 12px 20px;

    font-family: Montserrat, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #ffffff;

    &_disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &__icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url('../../../images/main/icons/Arrow-Chevron-Forward.svg');
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 10px;
    }

    &_color {
        &-white {
            background-color: #ffffff;
            border: 1px solid #2DC8C8;
            color: #2DC8C8;

            & .klickly-button__icon {
                background-image: url('../../../images/main/icons/forward-arrow-light-green.svg');
            }
        }
    }

    @media (max-width: 615px) {
        margin: 0 auto;
    }
}
