@import '../../../../styles/variables';

.q4-partner {
    &-wrapper {
        padding: 30px 70px 40px 30px;
        margin-bottom: 20px;
        max-width: 750px;
        border-radius: 3px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        transition: box-shadow linear .1s;

        &:hover {
            -webkit-box-shadow: 0px 10px 23px -12px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 10px 23px -12px rgba(0,0,0,0.75);
            box-shadow: 0px 10px 23px -12px rgba(0,0,0,0.75);
        }

        @media (max-width: 667px) {
            padding: 0;
            margin: 20px;
            border: 1px solid #e4e4e4;
            border-radius: 4px;
        }
    }

    &-content {
        display: flex;

        @media (max-width: 667px) {
            flex-direction: column;
            align-items: center;
        }
    }

    &__logo-img {
        width: 250px;
        height: 250px;
        object-fit: contain;

        @media (max-width: 667px) {
            width: 100px;
            height: 100px;
            margin: 20px;
        }
    }

    &__info {
        margin: 20px 0 0 20px;
        max-width: 450px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @media (max-width: 667px) {
            margin: 20px;
            width: calc(100% - 40px);
        }
    }

    &__name {
        font-family: $font-bold;
        font-weight: 600;
        font-size: 38px;
        color: #4a4a4a;
        line-height: 1.2em;

        @media (max-width: 667px) {
            font-size: 28px;
        }
    }

    &__category {
        font-family: $font-regular;
        color: #8d8d8d;
        line-height: 1.4em;
        margin-bottom: 10px;

        @media (max-width: 667px) {
            font-size: 16px;
            margin: 10px 0;
        }
    }

    &__about {
        font-size: 24px;
        color: #4a4a4a;
        line-height: 1.1em;

        @media (max-width: 667px) {
            font-size: 18px;
            margin: 10px 0;
        }
    }

    &__offer {
        font-family: $font-bold;
        font-weight: 600;
        color: #4a4a4a;
        font-size: 20px;
        line-height: 1.2em;

        @media (max-width: 667px) {
            font-size: 16px;
            margin: 10px 0;
        }
    }

    &__button-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;

        @media (max-width: 667px) {
            justify-content: center;
            margin: 0px 10px 20px;
            width: calc(100% - 20px);
        }
    }

    &__button {
        width: 200px;
        height: 40px;
        background-color: #da1781;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-family: $font-bold;
        font-weight: 600;
        font-size: 17px;
        cursor: pointer;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        transition: box-shadow linear .1s;

        &:hover {
            -webkit-box-shadow: 0px 10px 23px -12px rgba(218, 23, 129, 0.75);
            -moz-box-shadow: 0px 10px 23px -12px rgba(218, 23, 129, 0.75);
            box-shadow: 0px 10px 23px -12px rgba(218, 23, 129, 0.75);
        }
    }
}
