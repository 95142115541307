@import "../../../../styles/general";

.footer {
    position: relative;
    width: 100%;
    bottom: 0;
    background: url('../../../../images/png/footer.png') 100% 90% no-repeat;
    background-size: cover;
    @extend .space-inside;

    &__content {
        max-width: 1024px;
        width: 100%;
        margin: 0 auto;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 45px 20px;

        @media (max-width: 667px) {
            flex-direction: column;

            & .dropdown-menu__wrapper {
                margin-top: 0px;
                margin-bottom: 20px;
            }
        }
    }
}

.dropdown-menu {

    &__wrapper {
        width: 250px;
    }

    &__title {
        font-family: Montserrat, Helvetica, sans-serif;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        cursor: pointer;
    }

    &__title_arrow {
        display: inline-block;
        color: #ffffff;
    }

    &__list-wrapper {
        list-style: none;
        background-color: #ffffff;
        border-radius: 3px;
    }

    &__list-item {
        height: 37px;
        font-family: Montserrat, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        color: #4a4a4a;
        background-color: #ffffff;
        border-radius: 3px;
        border: 1px solid #e2e2e2e2;
        padding: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:not(:first-child) {
            border-top: none;
        }
    }

    &__content_bottom {
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 1px 11px 0 rgba(0,0,0,.22);
        visibility: hidden;
        transform: translateY(-310px);
        transition: visibility 0s linear .2s, transform .2s linear;
        max-height: 0;

        &.is-opened {
            visibility: visible;

            -webkit-transform: translateY(-330px);
            -moz-transform: translateY(-330px);
            -ms-transform: translateY(-330px);
            -o-transform: translateY(-330px);
            transform: translateY(-330px);

            -webkit-transition-delay: 0s;
            -moz-transition-delay: 0s;
            -ms-transition-delay: 0s;
            -o-transition-delay: 0s;
            transition-delay: 0s;
        }
    }
}
