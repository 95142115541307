

.klickly-brands {
    padding: 0 80px;

    @media (max-width: 860px) {
        padding: 0;
        overflow: hidden;
        margin-top: -20px;
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;

        @media (max-width: 860px) {
            width: fit-content;
            -webkit-animation: scroll-partners-mobile 10s linear infinite;
            animation: scroll-partners-mobile 10s linear infinite;
        }
    }

    &__mashable {
        width: 147.76px;
        height: 25.65px;
        background-image: url('../../../../images/main/brands/mash.svg');
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 18px;
        opacity: 0.54;

        @media (max-width: 860px) {
            width: 87px;
            height: 15px;
            opacity: 1;
        }
    }

    &__entrepreneur {
        width: 163.12px;
        height: 32.36px;
        background-image: url('../../../../images/main/brands/entrepreneur.svg');
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 18px;
        opacity: 0.54;
        margin-top: 8px;

        @media (max-width: 860px) {
            width: 99px;
            height: 19px;
            opacity: 1;
            margin-top: 4px;
        }
    }

    &__forbes {
        width: 99.68px;
        height: 26.21px;
        background-image: url('../../../../images/main/brands/forbes.svg');
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 18px;
        opacity: 0.54;

        @media (max-width: 860px) {
            width: 62px;
            height: 16px;
            opacity: 1;
        }
    }

    &__inc {
        width: 76.23px;
        height: 26.71px;
        background-image: url('../../../../images/main/brands/inc.svg');
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 18px;
        opacity: 0.54;

        @media (max-width: 860px) {
            width: 45px;
            height: 15.5px;
            opacity: 1;
        }
    }

    &__huffpost {
        width: 185.12px;
        height: 22.35px;
        background-image: url('../../../../images/main/brands/huffPost.svg');
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 18px;
        opacity: 0.54;

        @media (max-width: 860px) {
            width: 122px;
            height: 14.3px;
            opacity: 1;
        }
    }

    &__usa-today {
        width: 167px;
        height: 25px;
        background-image: url('../../../../images/main/brands/USAToday.svg');
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0 18px;
        opacity: 0.54;

        @media (max-width: 860px) {
            width: 167px;
            height: 15px;
            opacity: 1;
        }
    }
}

@keyframes scroll-partners-mobile {
	0% {
        transform: translateX(0);
    }
	100% {
        transform: translateX(calc(-595px));
    }
}

@-webkit-keyframes scroll-partners-mobile {
	0% {
        transform: translateX(0);
    }
	100% {
        transform: translateX(calc(-595px));
    }
}
