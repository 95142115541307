@import "../../styles/font_family";
@import "../../styles/variables";
@import "../../styles/colors";
@import "../../styles/mixins";

.landing-page-modal {
    position: absolute;
    left: calc(57% - 480px);
    padding: 60px 40px;
    width: 757px;
    min-height: 670px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: -35px 42px 115px 39px rgba(39, 44, 76, 0.11);
    border-radius: 34.2604px;
    z-index: -1;

    overflow: hidden;

    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);

    -webkit-transition: visibility 0s linear 200ms,
    -webkit-transform 200ms linear;
    -moz-transition: visibility 0s linear 200ms,
    -moz-transform 200ms linear;
    -ms-transition: visibility 0s linear 200ms,
    -ms-transform 200ms linear;
    -o-transition: visibility 0s linear 200ms,
    -o-transform 200ms linear;
    transition: visibility 0s linear 200ms,
    transform 200ms linear;

    @media screen and (min-width: 320px) and (max-width: 959px) {
        top: 50px;
        left: 0;
        right: 0;
        margin: auto;
        padding: 30px 15px;
        width: calc(100% - 44px);
        min-height: 601px;
        transform: translateY(-100%);
    }

    &__close {
        margin-left: auto;
        cursor: pointer;

        @media screen and (min-width: 320px) and (max-width: 959px) {
          width: 22px;
          height: 22px;

          & img {
            width: 80%;
            height: 80%;
          }
        }
    }

    &__input--error.landing-page-modal__select {
        color: #d0011b;
        background-color: #ffeef0;
        border-color: #f97d60;
    }

    &__label-for-select {
        position: relative;
        display: inline-block;
        width: 100%;

        & .error-message {
            bottom: 0;
            z-index: 2;
            font-size: 10px;
            font-family: Montserrat, Helvetica, sans-serif;
            color: #f64316;
        }
    }

    &__label-for-select:before {
        position: absolute;
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        top: 45%;
        right: 16px;
        transform: translateY(-45%) rotate(-45deg);
        border-top: 1px solid $purpley-grey;
        border-right: 1px solid $purpley-grey;
        z-index: 0;

        @media screen and (min-width: 320px) and (max-width: 959px) {
          width: 6px;
          height: 6px;
        }
    }

    &__label-for-select:after {
        position: absolute;
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        bottom: 45%;
        right: 26px;
        transform: translateY(45%) rotate(45deg);
        border-bottom: 2px solid #77838F;
        border-right: 2px solid #77838F;
        z-index: 1;

        @media screen and (min-width: 320px) and (max-width: 959px) {
            width: 6px;
            height: 6px;
        }
    }

    &__select {
        position: relative;
        width: 100%;
        padding: 0 24px;
        display: block;
        height: 64px;
        background-color: #F4F4F4;
        border: none;
        font-size: 16px;
        line-height: 24px;
        color: #77838F;
        outline: none;
        font-family: Montserrat, Helvetica, sans-serif;
        font-weight: 300;
        z-index: 1;
        cursor: pointer;

        -webkit-appearance: none;
        line-height: 47px;
        /* for FF */
        -moz-appearance: none;
        text-indent: 0.01px;
        text-overflow: '';
        /* for IE */
        -ms-appearance: none;
        appearance: none !important;

        &::-ms-expand {
            display: none;
        }

        &:before {
            position: absolute;
            content: '';
            width: 20px;
            height: 20px;
            display: block;
            top: 0;
            left: 0;
        }
    }

    &__select.active {
        font-size: 21px;
        color: $charcoal-grey;
        outline: none;
        font-family: $font-regular;
        font-weight: 600;

        @media screen and (min-width: 320px) and (max-width: 959px) {
          font-size: 13px;
          height: 38px;
          line-height: 38px;
        }
    }

    &--active {
        z-index: 1;
        top: 50%;

        visibility: visible;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);

        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -o-transition-delay: 0s;
        transition-delay: 0s;

        @media screen and (min-width: 320px) and (max-width: 959px) {
            z-index: 1;
            top: 50px;
            visibility: visible;
            -webkit-transform: translateY(0%);
            -moz-transform: translateY(0%);
            -ms-transform: translateY(0%);
            -o-transform: translateY(0%);
            transform: translateY(0%);
            -webkit-transition-delay: 0s;
            -moz-transition-delay: 0s;
            -ms-transition-delay: 0s;
            -o-transition-delay: 0s;
            transition-delay: 0s;
        }
    }

    &--big {
        width: 1024px;
        left: calc(50% - 512px);
    }

    &-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    &-title {
        font-family: Montserrat, Helvetica, sans-serif;
        font-size: 26px;
        font-weight: 600;
        text-align: left;
        color: #1C3144;

        @media screen and (min-width: 320px) and (max-width: 959px) {
            font-size: 24px;
        }
    }

    &-desc {
        font-family: $font-regular;
        font-size: 17px;
        text-align: left;
        color: $purpley-grey;
        margin-top: 10px;
        margin-bottom: 20px;

        @media screen and (min-width: 320px) and (max-width: 959px) {
          margin: 15px 0;
        }
    }

    &-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;

        @media screen and (min-width: 320px) and (max-width: 959px) {
          margin-top: 30px;
        }
    }

    &-footer {
        margin-top: 15px;
        display: flex;

        @media screen and (min-width: 320px) and (max-width: 959px) {
          justify-content: center;
        }
    }

    &-success {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 85px;

        @media screen and (min-width: 320px) and (max-width: 959px) {

          display: block;
          text-align: center;
          margin-top: 5%;

          & > img {
            width: 100px;
            height: 100px;
          }
        }

        &__heading {
            font-size: 48px;
            font-weight: bold;
            line-height: 1.04;
            color: $mirage;
            margin: 62px 0 5px 0;

            @media screen and (min-width: 320px) and (max-width: 959px) {
                font-size: 34px;
            }
        }

        &__text {
            font-size: 30px;
            line-height: 1.67;
            color: $charcoal-grey;

            @media screen and (min-width: 320px) and (max-width: 959px) {
              font-size: 16px;
            }
        }
    }

    & .landing-page-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 152px;
        height: 48px;
        background-color: #04080F;
        border-radius: 8px;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0 24px;

        font-family: Montserrat, Helvetica, sans-serif;
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        color: #ffffff;

        @media (max-width: 615px) {
            width: 171px;
            height: 56px;
            padding: 0 20px;

            font-size: 14px;
            line-height: 16px;
        }

        &-icon {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-image: url('../../images/main/icons/Arrow-Chevron-Forward.svg');
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 12px;
        }

        @media screen and (min-width: 320px) and (max-width: 959px) {
            width: 100%;
            justify-content: center;

            &-icon {
                margin-left: 10px;
            }
        }
    }
}

.landing-page-modal__input.landing-page-modal__input--error::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $scarlet;
}

.landing-page-modal__input.landing-page-modal__input--error::-moz-placeholder { /* Firefox 19+ */
    color: $scarlet;
}

.landing-page-modal__input--error.landing-page-modal__input:-ms-input-placeholder { /* IE 10+ */
    color: $scarlet;
}

.landing-page-modal__input--error.landing-page-modal__input:-moz-placeholder { /* Firefox 18- */
    color: $scarlet;
}
